import uniqWith from 'lodash/uniqWith';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  DO_NOT_CARE_STAFF,
  MAX_STAFF_FILTER_SELECTIONS,
} from '../../../../constants/constants';
import {
  Location,
  LocationType,
  Service,
  StaffMember,
} from '@wix/ambassador-bookings-services-v2-service/types';
import {
  getServiceCategoryId,
  getServiceCategoryName,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import { isCalendarPage, isCalendarWidget } from '../../../../utils/presets';

export enum FilterTypes {
  SERVICE = 'SERVICE',
  LOCATION = 'LOCATION',
  STAFF_MEMBER = 'STAFF_MEMBER',
}

export type FilterOption = {
  label: string;
  srOnlyLabel?: string;
  value: string;
  selected: boolean;
  indeterminate?: boolean;
  children?: FilterOption[];
};

export type FilterViewModel = {
  id: FilterTypes;
  label: string;
  options: FilterOption[];
  maxSelectionNumber?: number;
  note?: string;
};

export const memoizedFiltersViewModel: MemoizedViewModalFactory<filterviewmodel> =
  {
    dependencies: {
      state: ['servicesInView', 'filterOptions'],
      settings: [
        'serviceLabel',
        'locationLabel',
        'staffMemberLabel',
        'headerLocationFilterVisibility',
        'headerStaffFilterVisibility',
        'headerServiceFilterVisibility',
        'headerFiltersVisibility',
      ],
    },
  };

type CategoryData = { id: string; name: string; services: Service[] };

function getServiceCategories(servicesInView: Service[]): CategoryData[] {
  const categories: Record<string, CategoryData=""> = {};
  servicesInView.forEach((service) => {
    const category = categories[getServiceCategoryId(service)!];
    if (category) {
      category.services.push(service);
    } else {
      categories[getServiceCategoryId(service)!] = {
        id: getServiceCategoryId(service)!,
        name: getServiceCategoryName(service)!,
        services: [service],
      };
    }
  });
  const sortedCategories = Object.values(categories);
  sortedCategories.sort(({ name: firstCategory }, { name: secondCategory }) =>
    firstCategory.localeCompare(secondCategory),
  );
  sortedCategories.forEach(({ services }) =>
    services.sort((firstService, secondService) =>
      firstService.name!.localeCompare(secondService.name!),
    ),
  );

  return sortedCategories;
}

function getServiceFilterOptions(
  categories: CategoryData[],
  selectedServices: string[],
  context: CalendarContext,
): FilterOption[] {
  const selectedServicesSet = new Set(selectedServices);
  const serviceToFilterOption = (service: Service): FilterOption => ({
    value: service.id!,
    label: service.name!,
    selected: selectedServicesSet.has(service.id!),
  });

  if (categories.length === 1) {
    return categories[0].services.map(serviceToFilterOption);
  } else {
    return categories
      .map(({ name, id, services }): FilterOption => {
        const numberOfSelectedCategoryServices = services.filter((service) =>
          selectedServicesSet.has(service.id!),
        ).length;

        return {
          value: id,
          label: name,
          srOnlyLabel: context.t(
            'filter.accessibility.service-filter.parent-label',
            { categoryName: name },
          ),
          selected: services.length === numberOfSelectedCategoryServices,
          indeterminate:
            numberOfSelectedCategoryServices > 0 &&
            numberOfSelectedCategoryServices < services.length,
          children: services.map(serviceToFilterOption),
        };
      })
      .flat();
  }
}

function createServiceFilterViewModel(
  state: CalendarState,
  context: CalendarContext,
): FilterViewModel | undefined {
  const { getContent, reportError, settingsParams, settings, experiments } =
    context;
  const { servicesInView, filterOptions } = state;

  const isServiceFilterVisible =
    settings.get(settingsParams.headerServiceFilterVisibility) ||
    experiments.enabled('specs.bookings.calendarDisplayPerBreakpoint');

  try {
    if (isServiceFilterVisible && servicesInView.length > 1) {
      const categories = getServiceCategories(servicesInView);
      const options = getServiceFilterOptions(
        categories,
        filterOptions.SERVICE,
        context,
      );
      return {
        label: getContent({
          settingsParam: settingsParams.serviceLabel,
          translationKey: 'app.settings.defaults.service-label',
        }),
        options,
        id: FilterTypes.SERVICE,
      };
    }
  } catch (e) {
    reportError(e as string | Error);
  }
}

function createLocationFilterViewModel(
  state: CalendarState,
  context: CalendarContext,
): FilterViewModel | undefined {
  const {
    getContent,
    reportError,
    settingsParams,
    settings,
    experiments,
    preset,
  } = context;
  const { filterOptions, servicesInView } = state;
  const isCalendar = isCalendarPage(preset) || isCalendarWidget(preset);

  const isLocationFilterVisible = experiments.enabled(
    'specs.bookings.calendarDisplayPerBreakpoint',
  )
    ? isCalendar
    : settings.get(settingsParams.headerLocationFilterVisibility);

  try {
    if (!isLocationFilterVisible) {
      return;
    }

    const availableLocations = (
      getFilterOptions({
        servicesInView,
        optionType: 'locations',
      }) as Location[]
    ).filter(({ type }) => type === LocationType.BUSINESS);
    if (availableLocations.length > 1) {
      return {
        label: getContent({
          settingsParam: settingsParams.locationLabel,
          translationKey: 'app.settings.defaults.location-label',
        }),
        options: availableLocations
          .sort((firstLocation, secondLocation) =>
            (firstLocation.business?.name || '').localeCompare(
              secondLocation.business?.name || '',
            ),
          )
          .map((location) => ({
            selected: filterOptions.LOCATION.includes(location?.id!),
            label: location.business?.name || '',
            value: location.id || '',
          })),
        id: FilterTypes.LOCATION,
      };
    }
  } catch (e) {
    reportError(e as string | Error);
  }
}

function createStaffMemberFilterViewModel(
  state: CalendarState,
  context: CalendarContext,
): FilterViewModel | undefined {
  const { getContent, reportError, settingsParams, settings, experiments } =
    context;
  const { filterOptions, servicesInView } = state;

  const isStaffFilterVisible =
    settings.get(settingsParams.headerStaffFilterVisibility) ||
    experiments.enabled('specs.bookings.calendarDisplayPerBreakpoint');

  try {
    if (!isStaffFilterVisible) {
      return;
    }
    const isAnyResource =
      filterOptions.STAFF_MEMBER.includes(DO_NOT_CARE_STAFF);

    if (isAnyResource) {
      return;
    }

    const availableStaffMembers = getFilterOptions({
      servicesInView,
      optionType: 'staffMembers',
    }) as StaffMember[];

    if (availableStaffMembers.length > 1) {
      return {
        label: getContent({
          settingsParam: settingsParams.staffMemberLabel,
          translationKey: 'app.settings.defaults.staff-member-label',
        }),
        options: availableStaffMembers
          .sort(({ name: firstStaffName }, { name: secondStaffName }) =>
            firstStaffName!.localeCompare(secondStaffName!),
          )
          .map((staffMember) => ({
            selected: filterOptions.STAFF_MEMBER.includes(
              staffMember.staffMemberId!,
            ),
            label: staffMember.name!,
            value: staffMember.staffMemberId!,
          })),
        id: FilterTypes.STAFF_MEMBER,
        maxSelectionNumber: MAX_STAFF_FILTER_SELECTIONS,
      };
    }
  } catch (e) {
    reportError(e as string | Error);
  }
}

export function createFilterViewModels({
  state,
  context,
}: ViewModelFactoryParams<calendarstate, CalendarContext="">): FilterViewModel[] {
  const notUndefined = <t>(القيمة: T): القيمة غير قابلة للإلغاء<t> =>
    value !== undefined;

  return [
    createServiceFilterViewModel(state, context),
    createLocationFilterViewModel(state, context),
    createStaffMemberFilterViewModel(state, context),
  ].filter(notUndefined);
}

const getFilterOptions = ({
  servicesInView,
  optionType,
}: {
  servicesInView: Service[];
  optionType: Extract<keyof Service,="" 'staffMembers'="" |="" 'locations'="">;
}) => {
  return uniqWith(flatMap(servicesInView, optionType), isEqual);
};
</keyof></t></t></calendarstate,></string,></filterviewmodel>